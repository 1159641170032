import { Icon } from '@gitbook/icons';
import { DetailedHTMLProps, HTMLAttributes, useId } from 'react';

import { ClassValue, tcls } from '@/lib/tailwind';

import { Link } from '../primitives';

export type DropdownButtonProps<e extends="" HTMLElement="HTMLElement"> = 생략<
    Partial<detailedhtmlprops<htmlattributes<e>, E>>,
    'ref'
>;

/**
 * 드롭다운이 있는 버튼입니다.
 */
내보내기 기능 드롭다운<e extends="" HTMLElement="">(props: {
    /** Content of the button */
    button: (buttonProps: DropdownButtonProps<e>) => React.ReactNode;
    /** Content of the dropdown */
    children: React.ReactNode;
    /** Custom styles */
    className?: ClassValue;
}) {
    const { button, children, className } = props;
    const dropdownId = useId();

    return (
        <div className="{tcls('group/dropdown'," 'relative="" flex="" shrink="" min-w-0')}="">
            {button({
                id: dropdownId,
                tabIndex: 0,
                'aria-expanded': true,
                'aria-haspopup': true,
            })}
            <div tabIndex="{-1}" role="menu" aria-orientation="vertical" aria-labelledby="{dropdownId}" className="{tcls(" 'w-52',="" 'max-h-80',="" 'flex',="" 'absolute',="" 'top-full',="" 'left-0',="" 'z-20',="" 'origin-top-left',="" 'invisible',="" 'transition-opacity',="" 'duration-1000',="" 'group-hover="" dropdown:visible',="" 'group-focus-within="" className,="" )}="">
                <div className="{tcls(" 'mt-2',="" 'w-full',="" 'bg-light',="" 'rounded-lg',="" 'straight-corners:rounded-sm',="" 'p-2',="" 'shadow-1xs',="" 'overflow-auto',="" 'ring-1',="" 'ring-dark="" 1',="" 'ring-opacity-8',="" 'focus:outline-none',="" 'dark:bg-dark',="" 'dark:ring-light="" 2',="" )}="">
                    {자녀}
                </div>
            </div>
        </div>
    );
}

/**
 * Animated chevron to display in the dropdown button.
 */
export function DropdownChevron() {
    return (
        <icon icon="chevron-down" className="{tcls(" 'shrink-0',="" 'opacity-6',="" 'size-3',="" 'ms-1',="" 'transition-transform',="" 'group-hover="" dropdown:rotate-180',="" )}=""></icon>
    );
}

/**
 * Group of menu items in a dropdown.
 */
export function DropdownMenu(props: { children: React.ReactNode }) {
    const { children } = props;

    return <div className="{tcls('flex'," 'flex-col',="" 'gap-1')}="">{자녀}</div>;
}

/**
 * Menu item in a dropdown.
 */
export function DropdownMenuItem(props: {
    href: string | null;
    active?: boolean;
    className?: ClassValue;
    children: React.ReactNode;
}) {
    const { children, active = false, href, className } = props;

    if (href) {
        return (
            <link 2="" 3="" href="{href}" prefetch="{false}" className="{tcls(" 'px-3="" py-1="" text-sm="" rounded="" straight-corners:rounded-sm',="" active="" ?="" 'bg-primary="" dark:bg-light="" text-primary-600'="" :="" null,="" 'hover:bg-dark="" dark:hover:bg-light="" 2',="" className,="" )}="">
                {children}
            
        );
    }

    return (
        <div 8="" className="{tcls(" 'text-xs="" px-3="" py-1="" font-medium="" text-dark="" dark:text-light="" 8',="" className,="" )}="">
            {자녀}
        </div>
    );
}
</e></e></detailedhtmlprops<htmlattributes<e></e>